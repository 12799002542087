import * as React from "react";
import { connect } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";

import { createBrowserHistory } from "history";

import Cookies from "universal-cookie";
import { Helmet } from "react-helmet";
import ReactPiwik from "react-piwik";

import { updateDimensions, initAnaf } from "../actions/anafActions";
import { loadArtworks } from "../actions/artworkActions";

import { IArtwork } from "../reducers/dataReducer";
import { IAnaf, IDataset } from "../reducers/anafReducer";

import About from "./about/About";
import Artist from "./artist/Artist";
import Artwork from "./artist/Artwork";
import Contact from "./contact/Contact";
import Events from "./events/Events";
import Exhibition from "./exhibition/Exhibition";
import ExhibitionList from "./pastexhibitions/List";
import Explore from "./explore/Explore";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Highlights from "./highlight/Highlights";
import Home from "./home/Home";
import Intro from "./explore/Intro";
import News from "./news/News";
import Order from "./order/Order";
import Page from "./contentpage/Page";
import Savetheday from "./std/Savetheday";
import ScrollToTop from "./ScrollToTop";
import Search from "./search/Search";
import Splashpage from "./splashpage/Splashpage";
import Tour from "./tour/Tour";

interface State {}

interface parentProps {}

interface StoreProps {
  anaf: IAnaf;
}

interface DispatchProps {
  updateDimensions: Function;
  loadArtworks: Function;
  initAnaf: Function;
}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateDimensions: (w, h) => {
    dispatch(updateDimensions(w, h));
  },
  loadArtworks: () => {
    dispatch(loadArtworks());
  },
  initAnaf: (data: IDataset, lang: string) => {
    dispatch(initAnaf(data, lang));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

const customHistory = createBrowserHistory();

const piwik = new ReactPiwik({
  url: "https://anaf.fourdsoftware.com",
  siteId: 1,
  //  trackErrors: true,
});

const cookies = new Cookies();

export class App extends React.Component<Props, State> {
  refs: any;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.loadArtworks();

    let dataset = document.getElementById("dataset").innerHTML;
    let x = JSON.parse(dataset);
    this.props.initAnaf(x, this.selectLang());

    window.addEventListener("resize", () => this.updateDimensions());

    this.updateDimensions();
    setTimeout(() => this.updateDimensions(), 100);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.updateDimensions());
  }

  updateDimensions() {
    let w = this.refs.anaf.clientWidth;
    let h = window.innerHeight;
    this.props.updateDimensions(w, h);
  }

  componentDidUpdate() {
    let w = this.refs.anaf.clientWidth;
  }

  render() {
    return (
      <Router history={piwik.connectToHistory(customHistory)}>
        <ScrollToTop>
          <Helmet>
            <title>AkzoNobel Art Foundation</title>
            <meta name="description" content="AkzoNobel Art Foundation" />
          </Helmet>

          <div ref="anaf">
            <Route path="/" component={Header} />
            <Switch>
              <Route exact path="/" component={Splashpage} />
              <Route exact path="/home" component={Home} />
              <Route path="/exhibitions" component={ExhibitionList} />
              <Route path="/highlights" component={Highlights} />
              <Route exact path="/exhibition/:id" component={Exhibition} />
              <Route
                exact
                path="/exhibition/:id/:page"
                component={Exhibition}
              />
              <Route exact path="/tour/:id" component={Tour} />
              <Route exact path="/tour/:id/:name" component={Tour} />

              <Route exact path="/events/" component={Events} />
              <Route exact path="/in_the_media/" component={News} />
              <Route exact path="/artwork/:id/:name" component={Artwork} />
              <Route exact path="/artist/:id/:name" component={Artist} />
              <Route path="/en/artists/:id" component={Artist} />
              <Route path="/nl/artists/:id" component={Artist} />
              <Route exact path="/explore/intro" component={Intro} />
              <Route exact path="/explore" component={Explore} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/about_us" component={About} />
              <Route exact path="/search" component={Search} />
              {/* <Route exact path="/order" component={Order} /> */}
              <Route path="/savethedate" component={Savetheday} />
              <Route path="/page/:slug" component={Page} />
              <Route path="/*" component={Home} />
            </Switch>
            <Route path="/" component={Footer} />
          </div>
        </ScrollToTop>
      </Router>
    );
  }

  selectLang() {
    let cookielang = cookies.get("anaf_lang");
    if (cookielang) return cookielang;

    let userLang: string = navigator.language.toLowerCase();

    switch (userLang) {
      case "nl":
      case "nl-nl":
      case "nl-be":
        return "NL";
        break;

      default:
        return "EN";
        break;
    }

    return "EN";
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
